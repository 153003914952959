import React, { useState } from "react";
import BlogCard from "../components/BlogCard";
import { Link } from "react-router-dom";
import "../styles/Blogs.scss";
import trashImage1 from "../assets/images/trash.webp";
import blogImage from "../assets/images/blogs.webp";

const Blogs = () => {
  const [blogs] = useState([
    {
      title: "OPĆI KVIZ",
      shortDescription:
        "Za sve informacije o kvizovima, pošaljite DM na instagramu @inquizicija.",
      content: `<div>
      <p>Inquizicija organira pub opći kviz svake srijede u Comodu.</p>
      <p>Za sve informacije o kvizovima, pošaljite DM na instagramu.</p>
      <p>
      </p>
      <a href="https://www.instagram.com/inquizicija?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" style=" color: #fff; font-weight: 600;">
      @inquizicija
      </a>
      </div>`,
      dateStart: "Svake srijede",
      dateEnd: "Od 20:00h",
      images: [blogImage],
      slug: "opći-kviz-srijedom",
    },
    {
      title: "TRASH OUT VOL.1",
      shortDescription:
        "Ovim putem Vas obavještavamo kako započinjemo sezonu ludih provoda u Comodu, a prvi na našoj listi će biti ni više ni manje nego dobro nam poznati Trash.",
      content: `<div>
      <p>Ovim putem Vas obavještavamo kako započinjemo sezonu ludih provoda u Comodu, a prvi na našoj listi će biti ni više ni manje nego dobro nam poznati Trash.</p>
      <p>DJ Rako će se pobrinuti da atmosfera bude na levelu, a mi uopće ne sumnjamo u to! 🤩</p>
      <p>Za sve Vas koji već dobro poznajete našu dnevnu ponudu pića, Izazivamo Vas da isprobate našu noćnu ponudu, uključujući hrpu novih cocktaila i shootera ( jer borovničke su boring ).</p>
      <p>Za sve informacije o eventu, cijenama i rezervacijama, slobodno se javite u DM, ili na wapp broj +385 99 6946 626 (Josip).</p>
      <p>*** Event je 18+ ***</p>
      <p>Požurite jer mjesta su ograničena a radi velike zainteresiranosti puni se velikom brzinom 🥰</p>
      </div>`,
      dateStart: "26.10.2024.",
      dateEnd: "Od 22:00h do 04:00h",
      images: [trashImage1],
      slug: "ktrash-vol-out-1",
    },
  ]);

  const [search, setSearch] = useState("");

  const checkSearch = (title) => {
    if (title.toLowerCase().includes(search.toLowerCase())) {
      return true;
    }

    return false;
  };

  const getSearchTitle = (title) => {
    if (search === "") {
      return <h4>{title}</h4>;
    } else {
      let titleArray = title.split(search);
      return (
        <h4>
          {titleArray[0]}
          <span className="searched">{search}</span>
          {titleArray[1]}
        </h4>
      );
    }
  };

  return (
    <div className="page blogs">
      <div className="blogs-top">
        <div className="blogs-top-header">
          <Link to="/">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
              />
            </svg>
            <span>Povratak</span>{" "}
          </Link>
          <h1>Događanja</h1>
        </div>
        <div className="search-bar">
          <input
            type="text"
            placeholder="Pretraži događanja"
            onChange={(e) => setSearch(e.target.value)}
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
            />
          </svg>
        </div>
      </div>
      <div className="blogs-container">
        {blogs.map((blog, index) => {
          if (checkSearch(blog.title)) {
            return (
              <BlogCard
                blog={blog}
                index={index}
                searchTitle={getSearchTitle}
              />
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

export default Blogs;
